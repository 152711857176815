<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="4" :md="12" :sm="24" :xs="24">
            <a-form-item label="申请人">
              <a-input
                v-model:value.trim="where.name"
                placeholder="请输入申请人"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="操作时间:">
              <a-range-picker
                v-model:value="daterange"
                value-format="YYYY-MM-DD"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :lg="4" :md="12" :sm="24" :xs="24">
            <a-form-item
              label="地区:"
              v-permission="'jyyw:heroRecommend:areaCode'"
            >
              <a-select
                allow-clear
                placeholder="请选择地区"
                v-model:value="where.areaCode"
              >
                <a-select-option
                  v-for="item in dataRole"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="4" :md="12" :sm="24" :xs="24"  >
            <a-form-item label="状态:">
              <a-select
                allow-clear
                placeholder="状态查询"
                v-model:value="where.processState"
              >
                <a-select-option
                  v-for="item in stateDict"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="4" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" danger @click="removeBatch" v-permission="'jyyw:heroRecommend:delete'">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
            <a-button @click="downloadBatch">
              <template #icon>
                <download-outlined />
              </template>
              <span>导出</span>
            </a-button>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger" v-permission="'jyyw:heroRecommend:delete'">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑 -->
    <RecommendEdit v-model:visible="showEdit" :data="current" @done="reload" />
    <!-- 详情 -->
    <RecommendDetail
      v-model:visible="showDetail"
      :data="current"
      @done="reload"
    />
  </div>
</template>

<script>
import setting from '@/config/setting'
import * as recommendApi from '@/api/jyyw/recommend'
import * as dictDataApi from '@/api/sys/dictData'
import moment from 'moment'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons-vue'
import RecommendEdit from './index-edit'
import RecommendDetail from './index-detail.vue'
export default {
  name: 'applicationIndex',
  components: {
    DeleteOutlined,
    DownloadOutlined,
    RecommendEdit,
    RecommendDetail
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '申请人',
          dataIndex: 'name'
        },
        {
          title: '申请人手机',
          dataIndex: 'phone'
        },
        {
          title: '事件日期',
          dataIndex: 'date'
        },
        {
          title: '地区',
          dataIndex: 'areaCodeName',
          sorter: true
        },
        {
          title: '编号',
          dataIndex: 'code',
          sorter: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '150px',
          sorter: true,
          customRender: ({ text }) => this.$util.toDateString(text)
        },
        {
          title: '处理时间',
          dataIndex: 'processTime',
          sorter: true
        },
        {
          title: '处理人',
          dataIndex: 'processUserName',
          sorter: true
        },
        {
          title: '状态',
          dataIndex: 'processStateName',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '150px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: { type: '申请' },
      // 表格排序条件
      order: { sort: 'createTime', order: 'desc' },
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      categoryList: [],
      // 日期范围选择
      daterange: [],
      // 地区字典项
      dataRole: [],
      // 状态字典项
      stateDict: []
    }
  },
  created() {
    this.queryDataRoles()
    this.queryDataStates()
  },
  methods: {
    datasource(option, callback) {
      recommendApi
        .page({
          ...this.where,
          ...this.order,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            res.data.map((item) => {
              if (item.time === null) {
                item.date = moment(item.date).format('YYYY-MM-DD')
              } else {
                item.date =
                  moment(item.date).format('YYYY-MM-DD') + ' ' + item.time
              }
              if (item.processState === 'received') {
                item.processStateName = '已接收'
              } else if (item.processState === 'forwarded') {
                item.processStateName = '已转发'
              }
            })
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.daterange = []
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      recommendApi
        .deleteById(row.id)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项吗?',
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.id) }
          recommendApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 批量删除 */
    downloadBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      const hide = this.$message.loading('请求中..', 0)
      const data = this.selection.map((d) => d.id)
      recommendApi
        .downloadBatch(data)
        .then((res) => {
          hide()
          if (res.code === 0) {
            const url = setting.downloadUrl + res.data
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute(
              'download',
              '申请' + new Date().getTime() + '.zip'
            )
            document.body.appendChild(link)
            link.click()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    /* 打开详情弹窗 */
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    // 地区字典项
    queryDataRoles() {
      dictDataApi
        .querydictdata('dataRole')
        .then((res) => {
          if (res.code === 0) {
            this.dataRole = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 状态字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('result')
        .then((res) => {
          if (res.code === 0) {
            this.stateDict = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }

  },
  watch: {
    daterange() {
      if (this.daterange && this.daterange.length === 2) {
        this.where.dateStart = this.daterange[0]
        this.where.dateEnd = this.daterange[1]
      } else {
        this.where.dateStart = null
        this.where.dateEnd = null
      }
    }
  }
}
</script>

<style scoped>
</style>
